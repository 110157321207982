// Default theme variables
:root {
  --background-color: #00c3ff;
  --background-color2: #33cfff;
  --buttonhover1: linear-gradient(145deg, #00b0e6, #00d1ff);
  --text-color: #ffffff;
}

// Dark theme variables
.dark-theme {
  --background-color: #202020;
  --background-color2: #333333;
  --text-color: #ffffff;
  --buttonhover-dark: linear-gradient(145deg, #373737, #2e2e2e);
}

$fontFamily: "Roboto";

.App {
  background-color: var(--background-color);
  font-family: "Roboto";
  font-weight: 100;
  color: var(--text-color);
}

.Avatar {
  display: flex;
  width: 200px;
}

.aboutBio {
  padding-left: 10vh;
  font-family: $fontFamily;
  position: "absolute"; 
  width: "600px"; 
}

.buttonNeomorphic {
  background-color: var(--background-color);
  border-radius: 10%;
  color: var(--text-color);
  font-size: larger;
  height: 40px;
  box-shadow: 17px 17px 33px #00b1e8, -17px -17px 33px #00d5ff;
  position: absolute;
  border-color: #00d5ff;
  border: 1px solid var(--background-color);
  width: 100px;
  transition: all 0.3s ease;
}

.buttonNeomorphic:hover {
  background: var(--buttonhover1);
  border: 1px solid var(--background-color);
  box-shadow: 5px 5px 11px #00b1e8, -5px -5px 11px #00d5ff;
}

.dark-theme .buttonNeomorphic {
  box-shadow: none;
}

.dark-theme .buttonNeomorphic:hover {
  background: var(--buttonhover-dark);
  box-shadow: inset -29px 29px 57px #141414, inset 29px -29px 57px #525252;
}

.navButton {
  border-radius: 30%;
  height: 80px;
  width: 100px;
  box-shadow: 17px 17px 33px #00b1e8, -17px -17px 33px #00d5ff;
  background: var(--background-color);
  border: 1px solid var(--background-color);
  color: var(--text-color);
  transition: all 0.3s ease;

  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
  
  @media (max-width: 992px) {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}

.navButton:hover {
  background: linear-gradient(145deg, #00b0e6, #00d1ff);
}

.dark-theme .navButton {
  box-shadow: none;
}

.dark-theme .navButton:hover {
  background: var(--buttonhover-dark);
  box-shadow: inset -29px 29px 57px #141414, inset 29px -29px 57px #525252;
}

.mainNav {
  align-items: flex-end;  
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  padding-bottom: 5%;
  position: fixed;
  width: 100%;
}

.icon {
  height: 45px;
  width: 45px;
}

.timeTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20%;
}

.workTitle {
  font-family: "Roboto";
  font-size: 100;
  padding-left: 10vh;
  padding-top: 10vh;
  position: "absolute";
}

.navProject {
  border-radius: 25px;
  height: 80%;
  width: 110%;
  background: var(--background-color);
  box-shadow: 17px 17px 33px #00b1e8, -17px -17px 33px #00d5ff;
  border: 1px solid var(--background-color);
  color: var(--text-color);
}

.navProject:hover {
  background: linear-gradient(145deg, #00b0e6, #00d1ff);
  box-shadow: 17px 17px 33px #00b1e8, -17px -17px 33px #00d5ff;
}

.dark-theme .navProject:hover {
  background: var(--buttonhover-dark);
}

.toggle-theme-btn {
  border-radius: 30%;
  height: 60px;
  width: 75px;
  box-shadow: 17px 17px 33px #00b1e8, -17px -17px 33px #00d5ff;
  background: var(--background-color);
  border: 1px solid var(--background-color);
  color: var(--text-color);
  transition: all 0.3s ease;

  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
  
  @media (max-width: 992px) {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}

.toggle-theme-btn:hover {
  background: linear-gradient(145deg, #00b0e6, #00d1ff);
}

.dark-theme .toggle-theme-btn {
  box-shadow: none;
}

.dark-theme .toggle-theme-btn:hover {
  background: var(--buttonhover-dark);
  box-shadow: inset -29px 29px 57px #141414, inset 29px -29px 57px #525252;
}

.resumeContent {
  text-align: left;
}

@media (max-width: 992px) {
  .text-col,
  .image-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-col {
    order: 2;
  }
  .image-col {
    order: 1;
  }
}


// Assuming you have some base styles for your sidebar already
.sidebar {
  position: fixed; // Fixed position
  left: -100%; // Start off-screen
  top: 0;
  width: 250px; // Or your desired width
  height: 100%; // Full-height to cover the whole screen vertically
  background-color: var(--background-color);
  color: var(--text-color);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; // In case the content is longer than the screen
  z-index: 10; // Make sure it's above other content
  padding: 20px; // Or your desired padding
}

// When the sidebar is visible, it translates in from the left
.sidebar.visible {
  transform: translateX(100%);
}

// Button styles inside the sidebar
.sidebar button {
  background: none;
  border: none;
  color: inherit; // Inherit color from sidebar
  text-align: left; // Align text to the left
  width: 100%; // Full width for better click area
  margin: 10px 0; // Add some margin between buttons?
  margin-top: 15%;
  padding: 10px; // Padding for spacing
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--background-color2); // Change background on hover
  }
}

// Responsive design to handle mobile view
@media (max-width: 768px) {
  .sidebar {
    width: 100%; // On mobile, the sidebar can take full width
  }
}
